import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Input, Radio, Checkbox } from "antd";

export function SendBitcoinCard() {
  // default to zetachain testnet tss address
  const [toAddress, setToAddress] = useState("tb1qy9pqmk2pd9sv63g27jt8r657wy0d9ueeh0nqur");
  // default to Alex's hotwallet
  const [zEVMAddress, setzEVMAddress] = useState("a4898cA6c338b9Ef67e66559ffE7A7e88bDeB909");
  const [satoshis, setSatoshis] = useState(1000);
  const [isLowercase, setIsLowercase] = useState(true);
  const [result, setResult] = useState({
    success: false,
    error: "",
    data: "",
  });
  const doc_url =
    "https://docs.unisat.io/dev/unisat-developer-center/unisat-wallet#sendbitcoin";

  return (
    <Card size="small" title="Send Bitcoin" style={{ margin: 10 }}>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Docs:</div>
        <a href={doc_url} target="_blank">
          {doc_url}
        </a>
      </div>

      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Receiver Address:</div>
        <Input
          defaultValue={toAddress}
          minLength={42}
          maxLength={42}
          onChange={(e) => {
            setToAddress(e.target.value);
          }}
        ></Input>
      </div>

      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>zEVM address (hex encoded):</div>
        <Input
          defaultValue={zEVMAddress}
          onChange={(e) => {
            setzEVMAddress(e.target.value);
          }}
        ></Input>
      </div>

      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Amount: </div>
        <Input
          defaultValue={satoshis}
          onChange={(e) => {
            setSatoshis(parseInt(e.target.value));
          }}
        ></Input>
      </div>

        <div style={{ textAlign: "left", marginTop: 10 }}>
        <Checkbox
          checked={isLowercase}
          onChange={(e) => setIsLowercase(e.target.checked)}
        >
          Make zEVM memo lowercase
        </Checkbox>
        </div>

      {result.success ? (
        <div style={{ textAlign: "left", marginTop: 10 }}>
          <div style={{ fontWeight: "bold" }}>Txid:</div>
          <div style={{ wordWrap: "break-word" }}>{result.data}</div>
        </div>
      ) : (
        <div style={{ textAlign: "left", marginTop: 10 }}>
          <div style={{ wordWrap: "break-word" }}>{result.error}</div>
        </div>
      )}

      <Button
        style={{ marginTop: 10 }}
        onClick={async () => {
          setResult({
            success: false,
            error: "Requesting...",
            data: "",
          });
          try {
            const memos = [isLowercase ? zEVMAddress.toLowerCase() : zEVMAddress];
            const txid = await (window as any).unisat.sendBitcoin(
              toAddress,
              satoshis,
              {
                memos
              }
            );

            setResult({
              success: true,
              error: "",
              data: txid,
            });
          } catch (e) {
            setResult({
              success: false,
              error: (e as any).message,
              data: "",
            });
          }
        }}
      >
        Submit
      </Button>
    </Card>
  );
}
